import React, { useEffect, useState } from "react";
import classes from "../css/LoginPage.module.css";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { baseUrl, loginApi } from "../api/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Lottie from "lottie-react";
import footballAnimation from "../animations/logo.json";
import { setUser } from "../slices/userSlice";

const LoginPage = () => {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.langSlice);

  const location = useLocation();

  const { status, path, message } = location.state || {
    status: null,
    path: null,
  };

  console.log({ status, path, message }, "status...path...");

  useEffect(() => {
    if (status == "redirect") {
      toast.info(message);
    }
  }, [status]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = { number: mobile, password };
      const res = await axios.post(`${baseUrl}${loginApi}`, data);
      dispatch(setUser(res?.data));
      toast.success(
        lang == "english"
          ? "Logged In Successfully!"
          : lang == "spanish"
          ? "¡Inició sesión exitosamente!"
          : lang == "french"
          ? "Connecté avec succès!"
          : lang == "arabic"
          ? "تم تسجيل الدخول بنجاح!"
          : "Logged In Successfully!"
      );
      setLoading(false);
      if (status == "redirect") {
        navigate(`${path}`);
      } else {
        navigate("/");
      }
      setMobile("");
      setPassword("");
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      console.log(error, "error");
    }
  };

  const navigateHandler = () => {
    if (status == "redirect") {
      navigate("/signup", {
        state: {
          status: "redirect",
          path: `${path}`,
          message: message,
        },
      });
    } else {
      navigate("/signup");
    }
  };

  return (
    <Layout active={7}>
      <Drawer active={7} />

      {loading && <Loader />}
      <div className={classes.container}>
        <div className={classes.sub_container}>
          <div className={classes.image_main_container}>
            <div className={classes.image_container}>
              {/* <img
                src="/assets/images/loginImage.png"
                alt="login"
                className={classes.login_image}
              /> */}
              <Lottie
                animationData={footballAnimation}
                loop={true}
                autoPlay={true}
              />
            </div>
          </div>

          <div className={classes.login_form}>
            <div className={classes.login_text}>
              <h2 className={classes.title}>Kickwinn</h2>
            </div>
            <form className={classes.form} onSubmit={submitHandler}>
              <InputText
                className="p-inputtext-lg"
                value={mobile}
                keyfilter="int"
                onChange={(e) => setMobile(e.target.value)}
                placeholder={
                  lang == "english"
                    ? "Number"
                    : lang == "spanish"
                    ? "Número"
                    : lang == "french"
                    ? "Nombre"
                    : lang == "arabic"
                    ? "رقم"
                    : "Number"
                }
                style={{ width: "100%" }}
              />
              <InputText
                className="p-inputtext-lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={
                  lang == "english"
                    ? "Password"
                    : lang == "spanish"
                    ? "Contraseña"
                    : lang == "french"
                    ? "Mot de passe"
                    : lang == "arabic"
                    ? "كلمة المرور"
                    : "Password"
                }
                type="password"
                style={{ width: "100%" }}
              />
              <button type="submit" className={classes.login_btn}>
                {lang == "english"
                  ? "Login"
                  : lang == "spanish"
                  ? "Acceso"
                  : lang == "french"
                  ? "Se connecter"
                  : lang == "arabic"
                  ? "تسجيل الدخول"
                  : "Login"}
              </button>
              <div onClick={() => navigateHandler()} className={classes.link}>
                {lang == "english"
                  ? "If you don't have an account , Sign Up"
                  : lang == "spanish"
                  ? "Si no tienes una cuenta, Regístrate"
                  : lang == "french"
                  ? "Si vous n'avez pas de compte, inscrivez-vous"
                  : lang == "arabic"
                  ? "إذا لم يكن لديك حساب، قم بالتسجيل"
                  : "If you don't have an account , Sign Up"}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
