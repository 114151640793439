import React, { useState } from "react";
import classes from "../css/LoginPage.module.css";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { baseUrl, loginApi, signupApi } from "../api/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Lottie from "lottie-react";
import footballAnimation from "../animations/logo.json";
import { setUser } from "../slices/userSlice";

const SignupPage = () => {
  const [userName, setUserName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const { lang } = useSelector((state) => state.langSlice);

  const { status, path } = location.state || { status: null, path: null };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = {
        name: userName,
        password: password,
        number: mobile,
      };
      const response = await axios.post(`${baseUrl}${signupApi}`, data);
      dispatch(setUser(response?.data));
      if (status == "redirect") {
        navigate(`${path}`);
      } else {
        navigate("/");
      }
      toast.success(
        lang == "english"
          ? "Signed Up Successfully!"
          : lang == "spanish"
          ? "¡Registrado exitosamente!"
          : lang == "french"
          ? "Inscription réussie !"
          : lang == "arabic"
          ? "تم التسجيل بنجاح!"
          : "Signed Up Successfully!"
      );
      setMobile("");
      setPassword("");
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoading(false);
    }
  };

  return (
    <Layout active={8}>
      <Drawer active={8} />

      {loading && <Loader />}
      <div className={classes.container}>
        <div className={classes.sub_container}>
          <div className={classes.image_main_container}>
            <div className={classes.image_container}>
              <Lottie
                animationData={footballAnimation}
                loop={true}
                autoPlay={true}
              />
            </div>
          </div>

          <div className={classes.login_form}>
            <div className={classes.login_text}>
              <h2 className={classes.title}>Kickwinn</h2>
            </div>
            <form className={classes.form} onSubmit={submitHandler}>
              <InputText
                type="text"
                className="p-inputtext-lg"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder={
                  lang == "english"
                    ? "Name"
                    : lang == "spanish"
                    ? "Nombre"
                    : lang == "french"
                    ? "Nom"
                    : lang == "arabic"
                    ? "اسم"
                    : "Name"
                }
                style={{ width: "100%" }}
              />
              <InputText
                className="p-inputtext-lg"
                value={mobile}
                keyfilter="int"
                onChange={(e) => setMobile(e.target.value)}
                placeholder={
                  lang == "english"
                    ? "Number"
                    : lang == "spanish"
                    ? "Número"
                    : lang == "french"
                    ? "Nombre"
                    : lang == "arabic"
                    ? "رقم"
                    : "Number"
                }
                style={{ width: "100%" }}
              />
              <InputText
                className="p-inputtext-lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={
                  lang == "english"
                    ? "Password"
                    : lang == "spanish"
                    ? "Contraseña"
                    : lang == "french"
                    ? "Mot de passe"
                    : lang == "arabic"
                    ? "كلمة المرور"
                    : "Password"
                }
                type="password"
                style={{ width: "100%" }}
              />
              <button type="submit" className={classes.login_btn}>
                {lang == "english"
                  ? "Sign up"
                  : lang == "spanish"
                  ? "Inscribirse"
                  : lang == "french"
                  ? "S'inscrire"
                  : lang == "arabic"
                  ? "اشتراك"
                  : "Sign up"}
              </button>
              <Link to="/login" className={classes.link}>
                {lang == "english"
                  ? "If you already have an account , Login"
                  : lang == "spanish"
                  ? "Si ya tiene una cuenta, inicie sesión"
                  : lang == "french"
                  ? "Si vous avez déjà un compte, connectez-vous"
                  : lang == "arabic"
                  ? "إذا كان لديك بالفعل حساب، قم بتسجيل الدخول"
                  : "If you already have an account , Login"}
              </Link>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignupPage;
