import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Auth = ({ children }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.userSlice);
  useEffect(() => {
    if (!user?.token) {
      navigate("/login");
    }
  }, [user]);
  return <>{children}</>;
};

export default Auth;
