import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseUrl, userRewardsApi } from "../api/api";
import { toast } from "react-toastify";
import Drawer from "../components/Drawer";
import ContestLayout from "../components/ContestLayout";
import classes from "../css/UserMatchesPage.module.css";
import MenuTabs from "../components/MenuTabs";
import { Fieldset } from "primereact/fieldset";
import { useNavigate } from "react-router-dom";

const PointsPage = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.userSlice);
  const [matches, setMatches] = useState([]);
  const [totalPoints, setTotalPoints] = useState("");

  const { lang } = useSelector((state) => state.langSlice);

  const navigate = useNavigate();
  const fetchDataFromBackend = async (loader) => {
    try {
      if (loader?.loading) {
        setLoading(true);
      }
      const response = await axios.post(
        `${baseUrl}${userRewardsApi}`,
        {
          userId: user?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      console.log(response, "response.........");
      if (response?.data?.status == 1) {
        setMatches(response?.data?.matches);
        setTotalPoints(response?.data?.totalPoints[0]?.sum_points);
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!user?.token) {
      navigate("/login", {
        state: {
          status: "redirect",
          path: "/user/points",
          message: "Login Or Sign Up",
        },
      });
      return;
    }
    fetchDataFromBackend({ loading: true });
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
      }
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout active={4} hideMenu={true}>
      <Drawer active={4} />
      <div className={classes.container}>
        <MenuTabs />
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.matches_container}>
              {/* <div className={classes.heading_container}>
                <h1 className={classes.heading}>Your Points</h1>
              </div> */}
              {matches?.length > 0 ? (
                <>
                  <div className={classes.points_container}>
                    <Fieldset
                      legend={
                        lang == "english"
                          ? "Total Points"
                          : lang == "spanish"
                          ? "Puntos totales"
                          : lang == "french"
                          ? "Points totaux"
                          : lang == "arabic"
                          ? "مجمل النقاط"
                          : "Total Points"
                      }
                    >
                      <p className={classes.message}>{totalPoints}</p>
                    </Fieldset>
                  </div>
                  {matches?.map((match, index) => {
                    return (
                      <ContestLayout
                        match={match}
                        key={index}
                        showPoints={true}
                      />
                    );
                  })}
                </>
              ) : (
                <div className={classes.message_box}>
                  <p className={classes.message}>
                    {lang == "english"
                      ? "No Match Points ...."
                      : lang == "spanish"
                      ? "No hay puntos de partido...."
                      : lang == "french"
                      ? "Pas de points de match...."
                      : lang == "arabic"
                      ? "لا توجد نقاط المباراة ...."
                      : "No Match Points ...."}
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default PointsPage;
