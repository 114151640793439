import React, { useEffect, useState } from "react";
import classes from "../css/UserMatchesPage.module.css";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import MenuTabs from "../components/MenuTabs";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { baseUrl, getUserMatchesApi } from "../api/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ContestLayout from "../components/ContestLayout";

const UserMatchesPage = () => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.userSlice);
  const { lang } = useSelector((state) => state.langSlice);
  const navigate = useNavigate();

  const fetchUserMatches = async (loader) => {
    try {
      if (loader?.loading == true) {
        setLoading(true);
      }
      const response = await axios.post(
        `${baseUrl}${getUserMatchesApi}`,
        {
          userId: user?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      console.log(response, "response");
      setMatches(response?.data?.message);
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user?.token) {
      navigate("/login", {
        state: {
          status: "redirect",
          path: "/user/matches",
          message: "Login or Sign Up",
        },
      });
      return;
    }
    fetchUserMatches({ loading: true });
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchUserMatches({ loading: false });
      }
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <Layout active={4} hideMenu={true}>
      <Drawer active={4} />
      <div className={classes.container}>
        <MenuTabs />
        {loading ? (
          <Loader />
        ) : (
          <div className={classes.matches_container}>
            <div className={classes.heading_container}>
              <h1 className={classes.heading}>
                {lang == "english"
                  ? "My Matches"
                  : lang == "spanish"
                  ? "Mis partidos"
                  : lang == "french"
                  ? "Mes matchs"
                  : lang == "arabic"
                  ? "مبارياتي"
                  : "My Matches"}
              </h1>
            </div>
            {matches?.length > 0 ? (
              matches?.map((match, index) => {
                return <ContestLayout match={match} key={index} />;
              })
            ) : (
              <div className={classes.message_box}>
                <p className={classes.message}>
                  {lang == "english"
                    ? "You have not joined any matches...."
                    : lang == "spanish"
                    ? "No te has unido a ninguna partida...."
                    : lang == "french"
                    ? "Vous n'avez rejoint aucun match...."
                    : lang == "arabic"
                    ? "لم تنضم إلى أي مباراة...."
                    : "You have not joined any matches...."}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UserMatchesPage;
