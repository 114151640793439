import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Loader from "../components/Loader";
import axios from "axios";
import { baseUrl, liveMatchesApi, recentMatchesApi } from "../api/api";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import classes from "../css/HomePage.module.css";
import MatchLayout from "../components/MatchLayout";
import { useSelector } from "react-redux";

const RecentPage = () => {
  const [loading, setLoading] = useState(true);
  const [recentMatches, setRecentMatches] = useState([]);
  const [todayMatches, setTodayMatches] = useState([]);
  const [todayMatchesResults, setTodayMatchesResults] = useState([]);

  const { lang } = useSelector((state) => state.langSlice);

  const fetchDataFromBackend = async (loader) => {
    try {
      if (loader?.loading) {
        setLoading(true);
      }
      const res = await axios.post(`${baseUrl}${liveMatchesApi}`, {
        timezone: moment.tz.guess(),
        date: moment().tz(moment.tz.guess()).format("YYYY-MM-DD"),
      });

      // TODAY MATCHES PREMIERE LEAGUE
      setTodayMatches(
        res?.data?.response?.data.filter(
          (data) =>
            data.matchStatus != "Match Finished" && data.matchTime == null
        )
      );

      // LIVE MATCHES PREMIERE LEAGUE
      //   setLiveMatches(
      //     res?.data?.response?.data.filter(
      //       (data) =>
      //         data.matchTime != null && data.matchStatus != "Match Finished"
      //     )
      //   );

      // TODAY MATCHES RESULTS PREMIERE LEAGUE
      setTodayMatchesResults(
        res?.data?.response?.data.filter(
          (data) => data.matchStatus == "Match Finished"
        )
      );

      console.log(res, "response....");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchRecentMatchesFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${baseUrl}${recentMatchesApi}`, {
        timezone: moment.tz.guess(),
      });
      console.log(res.data.response, "resssssssss recent");

      // setRecentMatches(
      //   res?.data?.response?.filter(
      //     (data) => data.matchStatus == "Match Finished"
      //   )
      // );

      const sortedMatches = res?.data?.response
      ?.filter((data) => data.matchStatus === "Match Finished")
      ?.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sorting by date (descending)

    setRecentMatches(sortedMatches);
    
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchDataFromBackend({ loading: true });
    fetchRecentMatchesFromBackend();
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
      }
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout active={2}>
      <Drawer active={2} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.matches_container}>
            {todayMatchesResults.length > 0 && (
              <>
                <div className={classes.heading_container}>
                  <h1 className={classes.heading}>
                    {lang == "english"
                      ? "Today Recent Matches"
                      : lang == "spanish"
                      ? "Hoy Partidos recientes"
                      : lang == "french"
                      ? "Matchs récents d'aujourd'hui"
                      : lang == "arabic"
                      ? "آخر مباريات اليوم"
                      : "Today Recent Matches"}
                  </h1>
                </div>
                {todayMatchesResults?.map((match, index) => {
                  return <MatchLayout match={match} key={index} />;
                })}
              </>
            )}
            {todayMatches.length > 0 && (
              <>
                <div className={classes.heading_container}>
                  <h1 className={classes.heading}>
                    {lang == "english"
                      ? "Upcoming Matches Today"
                      : lang == "spanish"
                      ? "Próximos partidos de hoy"
                      : lang == "french"
                      ? "Matchs à venir aujourd'hui"
                      : lang == "arabic"
                      ? "المباريات القادمة اليوم"
                      : "Upcoming Matches Today"}
                  </h1>
                </div>

                {todayMatches?.map((match, index) => {
                  return <MatchLayout match={match} key={index} />;
                })}
              </>
            )}

            <div className={classes.heading_container}>
              <h1 className={classes.heading}>
                {lang == "english"
                  ? "Recent Matches"
                  : lang == "spanish"
                  ? "Partidos recientes"
                  : lang == "french"
                  ? "Matchs récents"
                  : lang == "arabic"
                  ? "المباريات الأخيرة"
                  : "Recent Matches"}
              </h1>
            </div>
            {recentMatches?.length > 0 ? (
              recentMatches?.map((match, index) => {
                return <MatchLayout match={match} key={index} />;
              })
            ) : (
              <div className={classes.message_box}>
                <p className={classes.message}>
                  {lang == "english"
                    ? "There are no recent matches currently in this week.."
                    : lang == "spanish"
                    ? "No hay partidos recientes actualmente en esta semana."
                    : lang == "french"
                    ? "Il n’y a pas de matchs récents actuellement cette semaine."
                    : lang == "arabic"
                    ? "لا توجد مباريات أخيرة حاليا في هذا الأسبوع."
                    : "There are no recent matches currently in this week.."}
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </Layout>
  );
};

export default RecentPage;
