import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import {
  baseUrl,
  liveMatchesApi,
  recentMatchesApi,
  weeklyMatchesApi,
} from "../api/api";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import MatchLayout from "../components/MatchLayout";
import classes from "../css/HomePage.module.css";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState([]);
  const [recentMatches, setRecentMatches] = useState([]);
  const [liveMatches, setLiveMatches] = useState([]);
  const [todayMatches, setTodayMatches] = useState([]);
  const [todayMatchesResults, setTodayMatchesResults] = useState([]);
  const [weeklyMatches, setWeeklyMatches] = useState([]);
  const { lang } = useSelector((state) => state.langSlice);

  const navigate = useNavigate();

  const fetchDataFromBackend = async (loader) => {
    try {
      if (loader?.loading) {
        setLoading(true);
      }
      const res = await axios.post(`${baseUrl}${liveMatchesApi}`, {
        timezone: moment.tz.guess(),
        date: moment().tz(moment.tz.guess()).format("YYYY-MM-DD"),
      });

      console.log(res, "res");

      setMatches(res?.data?.response?.data);

      // TODAY MATCHES PREMIERE LEAGUE
      setTodayMatches(
        res?.data?.response?.data.filter(
          (data) =>
            data.matchStatus != "Match Finished" && data.matchTime == null
        )
      );

      // LIVE MATCHES PREMIERE LEAGUE
      setLiveMatches(
        res?.data?.response?.data.filter(
          (data) =>
            data.matchTime != null && data.matchStatus != "Match Finished"
        )
      );

      // TODAY MATCHES RESULTS PREMIERE LEAGUE
      setTodayMatchesResults(
        res?.data?.response?.data.filter(
          (data) => data.matchStatus == "Match Finished"
        )
      );

      console.log(res, "response....");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  console.log(liveMatches, "livematches");
  console.log(matches, "m");

  const fetchRecentMatchesFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${baseUrl}${recentMatchesApi}`, {
        timezone: moment.tz.guess(),
      });
      console.log(res.data.response, "resssssssss recent");
      // setRecentMatches(
      //   res?.data?.response?.filter(
      //     (data) => data.matchStatus == "Match Finished"
      //   )
      // );

      const sortedMatches = res?.data?.response
      ?.filter((data) => data.matchStatus === "Match Finished")
      ?.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sorting by date (descending)

    setRecentMatches(sortedMatches);
    
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
      console.log(error, "error");
    }
  };

  const fetchWeeklyMatchesFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${baseUrl}${weeklyMatchesApi}`, {
        timezone: moment.tz.guess(),
      });
      console.log(res.data.response, "response weekly matches");
      setWeeklyMatches(
        res?.data?.response?.filter((data) => data.matchTime == null)
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchDataFromBackend({ loading: true });
    fetchRecentMatchesFromBackend();
    fetchWeeklyMatchesFromBackend();
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
      }
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  console.log(todayMatches, "tm");
  return (
    <Layout active={1}>
      <Drawer active={1} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.matches_container}>
            <div className={classes.heading_container}>
              <h1 className={classes.heading}>
                {lang == "english"
                  ? "Live Matches"
                  : lang == "spanish"
                  ? "Partidos en vivo"
                  : lang == "french"
                  ? "Matchs en direct"
                  : lang == "arabic"
                  ? "المباريات الحية"
                  : "Live Matches"}
              </h1>
            </div>
            {liveMatches?.length > 0 ? (
              liveMatches?.map((match, index) => {
                return <MatchLayout match={match} key={index} />;
              })
            ) : (
              <div className={classes.message_box}>
                <p className={classes.message}>
                  {lang == "english"
                    ? "There are no live matches currently.."
                    : lang == "spanish"
                    ? "No hay partidos en vivo actualmente."
                    : lang == "french"
                    ? "Il n'y a pas de matchs en direct actuellement."
                    : lang == "arabic"
                    ? "لا توجد مباريات مباشرة حاليا.."
                    : "There are no live matches currently.."}
                </p>
              </div>
            )}
            {todayMatches.length > 0 && (
              <>
                <div className={classes.heading_container}>
                  <h1 className={classes.heading}>
                    {lang == "english"
                      ? "Upcoming Matches Today"
                      : lang == "spanish"
                      ? "Próximos partidos de hoy"
                      : lang == "french"
                      ? "Matchs à venir aujourd'hui"
                      : lang == "arabic"
                      ? "المباريات القادمة اليوم"
                      : "Upcoming Matches Today"}
                  </h1>
                </div>

                {todayMatches?.map((match, index) => {
                  return <MatchLayout match={match} key={index} />;
                })}
              </>
            )}
            {todayMatchesResults.length > 0 && (
              <>
                <div className={classes.heading_container}>
                  <h1 className={classes.heading}>
                    {lang == "english"
                      ? "Today Matches Results"
                      : lang == "spanish"
                      ? "Resultados de los partidos de hoy"
                      : lang == "french"
                      ? "Résultats des matchs d'aujourd'hui"
                      : lang == "arabic"
                      ? "نتائج مباريات اليوم"
                      : "Today Matches Results"}
                  </h1>
                </div>
                {todayMatchesResults?.map((match, index) => {
                  return <MatchLayout match={match} key={index} />;
                })}
              </>
            )}

            {recentMatches?.length > 0 && (
              <>
                <div className={classes.heading_container}>
                  <h1 className={classes.heading}>
                    {lang == "english"
                      ? "Recent Week Matches"
                      : lang == "spanish"
                      ? "Partidos de la semana reciente"
                      : lang == "french"
                      ? "Matchs de la semaine récente"
                      : lang == "arabic"
                      ? "مباريات الأسبوع الأخيرة"
                      : "Recent Week Matches"}
                  </h1>
                </div>
                {recentMatches?.map((match, index) => {
                  return <MatchLayout match={match} key={index} />;
                })}
              </>
            )}

            {weeklyMatches?.length > 0 && (
              <>
                <div className={classes.heading_container}>
                  <h1 className={classes.heading}>
                    {lang == "english"
                      ? "Upcoming Week Matches"
                      : lang == "spanish"
                      ? "Partidos de la próxima semana"
                      : lang == "french"
                      ? "Matchs de la semaine à venir"
                      : lang == "arabic"
                      ? "مباريات الاسبوع القادم"
                      : "Upcoming Week Matches"}
                  </h1>
                </div>
                {weeklyMatches?.map((match, index) => {
                  return <MatchLayout match={match} key={index} />;
                })}
              </>
            )}
          </div>
        </>
      )}
    </Layout>
  );
};

export default HomePage;
